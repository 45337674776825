<template>
  <div v-if="certList.length > 0">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >技能证书</van-divider
    >
    <van-row class="item" v-for="cert in certList" :key="cert.seq">
      <van-col span="24"
        >{{ cert.name }}【颁发日期：{{ cert.issueDate }}】【
        <van-tag
          :color="COLOR_M"
          v-if="cert.imageCode !== undefined && cert.imageCode !== ''"
          @click="imageView(cert.imageCode)"
          >查看图片</van-tag
        >
        <span v-else>未上传图片</span>
        】</van-col
      >
    </van-row>
  </div>
</template>
<script>
import { Divider, Tag } from 'vant'
export default {
  components: {
    [Divider.name]: Divider,
    [Tag.name]: Tag
  },
  data () {
    return {
      certList: []
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveCert (personCode) {
      var pd = { personCode: personCode, group: 'CRT', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var cert = { seq: element.seq, name: content.name, issueDate: content.issueDate, imageCode: content.imageCode }
          this.certList.push(cert)
        })
      }
    },
    imageView (code) {
      this.$router.push({ path: '/mde/storage/imageView', query: { code: code } })
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
