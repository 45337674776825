<template>
  <div v-if="eduList.length > 0">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >教育经历</van-divider
    >
    <van-row class="item" v-for="edu in eduList" :key="edu.seq">
      <van-col span="24"
        >{{ edu.school }}
        <van-tag :color="COLOR">{{ edu.qualify }}</van-tag> ({{
          edu.startDate
        }}
        到 {{ edu.endDate }})</van-col
      >
    </van-row>
  </div>
</template>
<script>
import { Divider, Tag } from 'vant'
export default {
  components: {
    [Divider.name]: Divider,
    [Tag.name]: Tag
  },
  data () {
    return {
      eduList: []
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveEdu (personCode) {
      var pd = { personCode: personCode, group: 'EDU', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var edu = { seq: element.seq, school: content.school, qualify: content.qualifyName, startDate: content.startDate, endDate: content.endDate }
          this.eduList.push(edu)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
