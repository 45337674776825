<template>
  <div v-if="workList.length > 0">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >工作经历</van-divider
    >
    <van-row class="item" v-for="work in workList" :key="work.seq">
      <van-col span="24"
        >{{ work.unitName }}({{ work.startDate }} 到
        {{ work.endDate }})</van-col
      >
    </van-row>
  </div>
</template>
<script>
import { Divider } from 'vant'
export default {
  components: {
    [Divider.name]: Divider
  },
  data () {
    return {
      workList: []
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveWork (personCode) {
      var pd = { personCode: personCode, group: 'WRK', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var work = { seq: element.seq, unitName: content.unitName, unitDesc: content.unitDesc, startDate: content.startDate, endDate: content.endDate }
          this.workList.push(work)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
