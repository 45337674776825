<template>
  <div v-if="projectList.length > 0">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >工作经验</van-divider
    >
    <div class="item" v-for="project in projectList" :key="project.seq">
      <van-row>
        <van-col span="6" class="title">项目名称：</van-col>
        <van-col span="18" class="text">{{ project.name }}</van-col>
      </van-row>
      <van-row>
        <van-col span="6" class="title">项目描述：</van-col>
        <van-col span="18" class="text">{{ project.description }}</van-col>
      </van-row>
      <van-row>
        <van-col span="6" class="title">工作岗位：</van-col>
        <van-col span="18" class="text">{{ project.jobName }}</van-col>
      </van-row>
      <van-row>
        <van-col span="6" class="title">参与时间：</van-col>
        <van-col span="18" class="text"
          >{{ project.startDate }} 到 {{ project.endDate }}</van-col
        >
      </van-row>
      <van-row>
        <van-col span="6" class="title">工作内容：</van-col>
        <van-col span="18" class="text">{{ project.jobContent }}</van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { Divider } from 'vant'
export default {
  components: {
    [Divider.name]: Divider
  },
  data () {
    return {
      projectList: []
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveProject (personCode) {
      var pd = { personCode: personCode, group: 'PJT', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var project = {
            seq: element.seq,
            name: content.name,
            description: content.description,
            jobName: content.jobName,
            jobContent: content.jobContent,
            startDate: content.startDate,
            endDate: content.endDate
          }
          this.projectList.push(project)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
    line-height: 20px;
  }
  .text {
    text-align: left;
    line-height: 20px;
  }
}
</style>
